@import '@ag-grid-community/core/dist/styles/ag-grid.scss';
@import '@ag-grid-community/core/dist/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss';

.ag-theme-cck {
  @include ag-theme-alpine(
    (
      borders-critical: true,
      borders-secondary: true,
      cell-horizontal-border: 1px solid #dde2eb
    )
  );

  .ag-rich-select {
    width: 500px;
  }
}

.ag-theme-noborder {
  @include ag-theme-alpine(
    (
      borders: false,
      borders-critical: true,
      borders-secondary: true,
      cell-horizontal-border: 1px solid #dde2eb
    )
  );

  .ag-rich-select {
    width: 500px;
  }
}

.ag-theme-design-evaluation {
  @include ag-theme-alpine(
    (
      borders: false,
      borders-critical: true,
      borders-secondary: true,
      cell-horizontal-border: 1px solid #dde2eb
    )
  );

  .ag-header {
    border-bottom-color: rgba(0, 0, 0, 0.12);
  }

  .ag-virtual-list-item {
    width: fit-content;
  }

  .ag-rich-select {
    width: 500px;
  }

  .ag-cell-wrap-text {
    white-space: pre-wrap;
  }
}

.ag-theme-wrap-text {
  @include ag-theme-alpine(
    (
      borders-critical: true,
      borders-secondary: true,
      cell-horizontal-border: 1px solid #dde2eb
    )
  );

  .ag-rich-select {
    width: 500px;
  }

  .ag-cell-wrap-text {
    white-space: pre-wrap;
  }
}
