body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100%;
  width: 100%;
  display: 'flex';
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: rgb(250, 250, 250);
  border: thin solid rgb(240, 240, 240);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgb(231, 231, 231);
  border: thin solid rgb(200, 200, 200);
}

@import '~antd/dist/antd.css';
